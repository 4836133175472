


































import {
  Component,
  Prop,
  PropSync,
  Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Job from '@modules/declaration/entities/Job';
import Employee from '@modules/declaration/entities/Employee';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class TransferJobModal extends Vue {
  @Prop({ type: Object, required: true })
  itemToDelete!: Job;

  @PropSync('show')
  visible!: Boolean;

  @firmUpdateModule.Getter('getActiveJobsCurrentFirm')
  activeJobs!: Array<Job>;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  @firmUpdateModule.Getter('getEmployeeWithPerson')
  getEmployee!: (uuid: string) => Employee;

  @firmUpdateModule.Getter('getCurrentEmployeeHasJobsByJob')
  getEmployeeHasJobsByJob!: (jobUuid: string) => EmployeeHasJob[];

  selectedTransferJob: Job | string = '';

  get deleteLabel(): string {
    return this.selectedTransferJob ? 'Transférer et supprimer' : 'Supprimer';
  }

  get selectedJob(): Job | null {
    return this.getJob(this.itemToDelete.uuid);
  }

  get selectedJobEmployeesNames(): Array<string> {
    if (!this.selectedJob) {
      return [];
    }

    const employeeHasJobs: EmployeeHasJob[] = this.getEmployeeHasJobsByJob(this.selectedJob.uuid);

    return employeeHasJobs.map(employeeHasJob => this.getEmployee(employeeHasJob.employeeUuid).label);
  }

  get otherJobs(): Array<Job> {
    const copy: Array<Job> = [];
    this.activeJobs.forEach((job) => {
      if (job.uuid !== this.itemToDelete.uuid) {
        copy.push(job);
      }
    });

    return copy;
  }

  valid() {
    this.$emit('valid', this.selectedTransferJob === 'AUCUN' ? '' : this.selectedTransferJob);
    this.$emit('update:show', false);
  }

  close() {
    this.$emit('update:show', false);
  }
}
